import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";
import { differenceInDays } from "date-fns";

const NewEmailComposer = ({
  currentAction,
  currentCustomer,
  currentNewEmail,
  currentNewEmailSubject,
  emailTemplates,
  handleCancel,
  populateTemplate,
  sendNewEmail,
  special,
  updateCurrentNewEmail,
  updateCurrentNewEmailSubject,
}) => {
  const newEmailComposerRef = useRef();
  const newEmailComposerCancel = useRef();

  useEffect(() => {
    if (
      currentCustomer.exactDuplicates &&
      currentCustomer.exactDuplicates.email
    ) {
      alert(
        "Another customer has the same email address - email is not available until this conflict is resolved. Edit one of the customers or merge the two to resolve."
      );
      if (newEmailComposerRef.current) {
        newEmailComposerCancel.current.click();
      }
    }

    // eslint-disable-next-line
  }, []);

  const getAIText = async () => {
    try {
      let dealershipName = "";

      if (currentAction.dealership === "61fe9c5425c57c0016cf649e") {
        dealershipName = "Auto Gals";
      } else if (currentAction.dealership === "6509b0684940b2001418b7df") {
        dealershipName = "ICars";
      } else {
        dealershipName = "Dorman's";
      }

      let customerFirst = currentAction.customer.firstName;

      let daysOld = differenceInDays(
        new Date(),
        new Date(currentAction.cycle.createdAt)
      );

      if (
        !currentAction.cycle ||
        !currentAction.cycle.active ||
        !currentAction.cycle.cycleType
      ) {
        return;
      }

      let instructions;

      if (currentAction.cycle.cycleType === "internet") {
        if (currentAction.cycle.internetSource !== "kbb-ico") {
          if (parseInt(daysOld) > 2) {
            instructions = `Please construct a 100 word e-mail from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are still interested in purchasing a vehicle like the one they asked about ${daysOld} days ago and informing them that the dealership would still love to have an opportunity to earn their business.`;
          } else {
            if (
              currentAction.cycle.vehicle &&
              currentAction.cycle.vehicle.make
            ) {
              instructions = `Please construct a 100 word e-mail without using the word "dream" from ${dealershipName} to a customer named ${customerFirst} informing them that the ${currentAction.cycle.vehicle.make} they want is available, specializes in financing, and that the dealership would love to have an opportunity to earn their business.`;
            } else {
              instructions = `Please construct a 100 word e-mail without using the word "dream" from ${dealershipName} to a customer named ${customerFirst} informing them that ${dealershipName} has a great selection of vehicles in stock, specializes in financing, and that the dealership would love to have an opportunity to earn their business.`;
            }
          }
        } else {
          if (parseInt(daysOld) > 2) {
            if (
              currentAction.cycle.vehicle &&
              currentAction.cycle.vehicle.make
            ) {
              instructions = `Please construct a 100 word e-mail from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are still interested in selling their ${currentAction.cycle.vehicle.make} and informing them that the dealership is still very interested in it.`;
            } else {
              instructions = `Please construct a 100 word e-mail from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are still interested in selling their current vehicle and informing them that the dealership is still very interested in it.`;
            }
          } else {
            if (
              currentAction.cycle.vehicle &&
              currentAction.cycle.vehicle.make
            ) {
              instructions = `Please construct a 100 word e-mail from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are interested in selling their ${currentAction.cycle.vehicle.make} and informing them that the dealership is very interested in it.`;
            } else {
              instructions = `Please construct a 100 word e-mail from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are interested in selling their current vehicle and informing them that the dealership is very interested in it.`;
            }
          }
        }
      } else if (currentAction.cycle.cycleType === "walkin") {
        instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} informing them that ${dealershipName} has a great selection of vehicles in stock, specializes in financing, and that the dealership would love to have an opportunity to earn their business.`;
      } else if (currentAction.cycle.cycleType === "phone") {
        instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are still interested in purchasing a vehicle like the one they called about ${daysOld} days ago and informing them that the dealership would still love to have an opportunity to earn their business.`;
      }

      let res = await axios
        .post(
          "/api/v1/email/getAIV1",
          {
            instructions,
          },
          axiosConfig
        )
        .catch((err) => {
          throw new Error(err.message);
        });

      updateCurrentNewEmail({
        target: { value: res.data.openairesponse.choices[0].text.trim() },
      });
    } catch (error) {
      console.log(error);
    }
    // 5/9/23
  };

  return (
    <div
      className={
        special ? "popup-content popup-content-special" : "popup-content"
      }
      ref={newEmailComposerRef}
    >
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          E-Mail Customer
        </p>
        <div
          onClick={handleCancel}
          id="hide-compose-new-email"
          ref={newEmailComposerCancel}
        >
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="form-row">
          <div className="dark-blue-text medium-text margin-right-small">
            Template:{" "}
          </div>
          <select
            name="email-template"
            id="email-template"
            className="form-select flex-grow small-text dark-blue-green-text"
            onChange={populateTemplate}
          >
            <option value="none">none</option>
            {emailTemplates.map((template) => {
              return (
                <option key={template.uuid} value={JSON.stringify(template)}>
                  {`${template.title}`}
                </option>
              );
            })}
          </select>
        </div>
        <div className="form-row">
          <div className="dark-blue-text medium-text margin-right-small">
            Subject:{" "}
          </div>
          <input
            className="form-field medium-text dark-blue-green-text flex-grow"
            value={currentNewEmailSubject && currentNewEmailSubject}
            onChange={updateCurrentNewEmailSubject}
            placeholder="- - - -"
          />
        </div>

        <textarea
          className="popup-body-container popup-body-container-taller popup-body-container-prewrap small-text dark-blue-green-text "
          value={currentNewEmail}
          onChange={updateCurrentNewEmail}
          placeholder="Type E-Mail body here..."
        ></textarea>
        {currentAction &&
          currentAction.dealership &&
          (currentAction.dealership === "61fe9c5425c57c0016cf649e" ||
            currentAction.dealership === "6509b0684940b2001418b7df") && (
            <button
              className="button-neutral large-text mb-3"
              onClick={getAIText}
            >
              Generate!
            </button>
          )}

        <button className="button-elevated large-text" onClick={sendNewEmail}>
          Send E-Mail
        </button>
      </div>
    </div>
  );
};

NewEmailComposer.propTypes = {
  currentCustomer: PropTypes.object.isRequired,
  currentNewEmail: PropTypes.string,
  currentNewEmailSubject: PropTypes.string,
  emailTemplates: PropTypes.array,
  handleCancel: PropTypes.func.isRequired,
  populateTemplate: PropTypes.func.isRequired,
  sendNewEmail: PropTypes.func.isRequired,
  special: PropTypes.bool.isRequired,
  updateCurrentNewEmail: PropTypes.func.isRequired,
  updateCurrentNewEmailSubject: PropTypes.func.isRequired,
};

export default NewEmailComposer;
