import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { parsePhoneNumber } from "libphonenumber-js";
import { differenceInDays } from "date-fns";
import axios from "axios";
import { axiosConfig } from "../../util/axiosConfig";
import { dupDoubleCheck } from "../../util/dupDoubleCheck";

const ActionText = ({
  currentCustomer,
  currentAction,
  currentTextBody,
  currentTexts,
  handleCancel,
  sendTextHandler,
  updateCurrentTextBody,
}) => {
  const cancelActionText = useRef();

  useEffect(() => {
    // console.log(currentAction);
    if (
      currentCustomer.exactDuplicates &&
      currentCustomer.exactDuplicates.phone
    ) {
      (async function () {
        const dupFound = await dupDoubleCheck(
          "phone",
          currentCustomer._id,
          currentCustomer.phoneNumber,
          currentCustomer.dealership
        );

        console.log("ACTIONTEXT DUP FOUND");

        if (dupFound) {
          alert(
            "Another customer has the same phone number - texting is not available until this conflict is resolved. Edit one of the customers or merge the two to resolve."
          );
          if (cancelActionText.current) {
            cancelActionText.current.click();
          }
        }
      })();
    }

    // eslint-disable-next-line
  }, []);

  const getAIText = async () => {
    try {
      let dealershipName = "";

      if (currentAction.dealership === "61fe9c5425c57c0016cf649e") {
        dealershipName = "Auto Gals";
      } else if (currentAction.dealership === "6509b0684940b2001418b7df") {
        dealershipName = "ICars";
      } else {
        dealershipName = "Dorman's";
      }

      let customerFirst = currentAction.customer.firstName;

      let daysOld = differenceInDays(
        new Date(),
        new Date(currentAction.cycle.createdAt)
      );

      if (
        !currentAction.cycle ||
        !currentAction.cycle.active ||
        !currentAction.cycle.cycleType
      ) {
        return;
      }

      let instructions;

      if (currentAction.cycle.cycleType === "internet") {
        if (currentAction.cycle.internetSource !== "kbb-ico") {
          if (parseInt(daysOld) > 2) {
            instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are still interested in purchasing a vehicle like the one they asked about ${daysOld} days ago and informing them that the dealership would still love to have an opportunity to earn their business.`;
          } else {
            if (
              currentAction.cycle.vehicle &&
              currentAction.cycle.vehicle.make
            ) {
              instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} informing them that the ${currentAction.cycle.vehicle.make} they want is available, specializes in financing, and that the dealership would love to have an opportunity to earn their business.`;
            } else {
              instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} informing them that ${dealershipName} has a great selection of vehicles in stock, specializes in financing, and that the dealership would love to have an opportunity to earn their business.`;
            }
          }
        } else {
          if (parseInt(daysOld) > 2) {
            if (
              currentAction.cycle.vehicle &&
              currentAction.cycle.vehicle.make
            ) {
              instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are still interested in selling their ${currentAction.cycle.vehicle.make} and informing them that the dealership is still very interested in it.`;
            } else {
              instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are still interested in selling their current vehicle and informing them that the dealership is still very interested in it.`;
            }
          } else {
            if (
              currentAction.cycle.vehicle &&
              currentAction.cycle.vehicle.make
            ) {
              instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are interested in selling their ${currentAction.cycle.vehicle.make} and informing them that the dealership is very interested in it.`;
            } else {
              instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are interested in selling their current vehicle and informing them that the dealership is very interested in it.`;
            }
          }
        }
      } else if (currentAction.cycle.cycleType === "walkin") {
        instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} informing them that ${dealershipName} has a great selection of vehicles in stock, specializes in financing, and that the dealership would love to have an opportunity to earn their business.`;
      } else if (currentAction.cycle.cycleType === "phone") {
        instructions = `Please construct a text message from ${dealershipName} to a customer named ${customerFirst} asking them whether or not they are still interested in purchasing a vehicle like the one they called about ${daysOld} days ago and informing them that the dealership would still love to have an opportunity to earn their business.`;
      }

      let res = await axios
        .post(
          "/api/v1/text/getAIV1",
          {
            instructions,
          },
          axiosConfig
        )
        .catch((err) => {
          throw new Error(err.message);
        });

      updateCurrentTextBody({
        target: { value: res.data.openairesponse.choices[0].text.trim() },
      });
    } catch (error) {
      console.log(error);
    }
    // 5/9/23
  };

  return (
    <div className="popup-content popup-content-special">
      <div className="popup-header">
        <p className="large-text white-text center-text flex-grow">
          Action Text
        </p>
        <div
          onClick={handleCancel}
          id="hide-text-conversation"
          ref={cancelActionText}
        >
          <svg className="icon-small icon-small-back">
            <use href="../../icons/symbol-defs.svg#icon-cancel"></use>
          </svg>
        </div>
      </div>
      <div className="popup-body popup-body-evenly">
        <div className="form-row margin-none">
          <label
            htmlFor="text-to"
            className="small-text dark-blue-text margin-right-large"
          >
            Customer Phone:
          </label>
          <input
            className="form-field small-text dark-blue-green-text flex-grow"
            id="text-to"
            value={
              currentCustomer &&
              currentCustomer.phoneNumber &&
              typeof currentCustomer.phoneNumber === "string" &&
              parsePhoneNumber(currentCustomer.phoneNumber)
                ? parsePhoneNumber(currentCustomer.phoneNumber).formatNational(
                    "US"
                  )
                : currentCustomer.phoneNumber
            }
            disabled
          />
        </div>
        <div className="popup-section small-text">
          {currentTexts &&
            currentTexts.map((cur, index) => {
              return cur.direction === "incoming" ? (
                <div className="form-row" key={index}>
                  <div className="incoming-text">{cur.body}</div>
                  <div></div>
                </div>
              ) : (
                <div className="form-row" key={index}>
                  <div></div>
                  <div className="outgoing-text">{cur.body}</div>
                </div>
              );
            })}
          <div
            id="bottom-of-texts"
            onClick={(e) => {
              // it was a pain in the ass to figure out how to do this
              const target = e.currentTarget;
              target.scrollIntoView({ behavior: "smooth" });
            }}
          ></div>
        </div>
        <textarea
          name="text-content"
          id="text-content"
          className="popup-section popup-section-short small-text medium-blue-text"
          placeholder="Type Here..."
          value={currentTextBody}
          onChange={updateCurrentTextBody}
        ></textarea>
        {currentAction &&
          currentAction.dealership &&
          (currentAction.dealership === "61fe9c5425c57c0016cf649e" ||
            currentAction.dealership === "6509b0684940b2001418b7df") && (
            <div className="form-row">
              <button
                className="button-neutral medium-text flex-grow"
                onClick={getAIText}
                id="action-text-send-btn"
              >
                Generate!
              </button>
            </div>
          )}

        <div className="form-row">
          <button
            className="button-elevated large-text flex-grow"
            onClick={sendTextHandler}
            id="action-text-send-btn"
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

ActionText.propTypes = {
  currentCustomer: PropTypes.object.isRequired,
  currentTextBody: PropTypes.string,
  currentTexts: PropTypes.array,
  handleCancel: PropTypes.func.isRequired,
  sendTextHandler: PropTypes.func.isRequired,
  updateCurrentTextBody: PropTypes.func.isRequired,
};

export default ActionText;
